import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Pages.css';
import '../styles/Dev.css';

function Dev() {
    const [selectedLang, setSelectedLang] = useState('fr');

    const resumeContent = {
        en: {
            header: {
                name: "Victor LEMAITRE",
                title: "Full-stack developer",
                description: "As a former freelance English-French translator, I discovered a true passion for development during my career change. As a serious, independent and hard-working individual, I am sure I'll be able to put these qualities to good use in a team.",
                contact: {
                    email: {
                        display: "victor.lemaitre1@gmail.com",
                        link: "mailto:victor.lemaitre1@gmail.com"
                    },
                    phone: {
                        display: "+33 6 35 90 74 91",
                        link: "tel:+33635907491"
                    },
                    linkedin: {
                        display: "linkedin.com/in/victor-lemaitre/",
                        link: "linkedin.com/in/victor-lemaitre/"
                    },
                    location: "Roubaix, France",
                    github: {
                        display: "github.com/victorlmtr",
                        link: "https://github.com/victorlmtr"
                    }
                }
            },
            experience: [
                {
                    title: "Backend/frontend developer (internship)",
                    period: "Sept 2024 - Dec 2024",
                    company: "Rémi Pozzo",
                    project: "1civil.it, mobile app for creating reports",
                    details: [
                        "Java Springboot backend, microservice architecture, Spring Security, JWT",
                        "Mobile frontend (iOS, Android) with Flutter, creating a report with GPS data",
                        "Figma, Docker, PostgreSQL, RabbitMQ, Angular, Trello…"
                    ]
                },
                {
                    title: "English-French translator",
                    period: "Feb 2016 - Dec 2024",
                    company: "Freelance work",
                    project: "",
                    details: [
                        "Subtitles for online conferences (Adobe Max, Adobe Summit, Atlassian Team…)",
                        "Technical translation (IT, medical, video game localization)",
                        "Interpreting, French to English transcription, proofreading"
                    ]
                },
                {
                    title: "EN-FR translator, interpreter",
                    period: "Jun 2019 - Aug 2019",
                    company: "CRIT, Lumbres, FR",
                    project: "",
                    details: [
                        "Technical translation for a wastewater treatment plant",
                        "Interpretation/dialogue with foreign workers on site"
                    ]
                },
                {
                    title: "EN-FR translator internship",
                    period: "Jan 2016 - Feb 2016",
                    company: "Language Insight, London, UK",
                    project: "",
                    details: [
                        "Technical translation (medical, IT) in a translation agency"
                    ]
                },
            ],
            education: [
                {
                    title: "Level 6 RNCP title in Software Design & development",
                    period: "2023 - 2024",
                    school: "AFPA Roubaix",
                    project: "Scrontch, grocery and recipe management app",
                    details: [
                        "Java Springboot backend, JWToken, Spring Security",
                        "Native Android frontend with Jetpack Compose, admin frontend Angular",
                        "Deployed app on Linux Ubuntu VPS, Nginx",
                    ]
                },
                {
                    title: "Apple Foundation Program Extended",
                    period: "October 2023",
                    school: "Simplon Montreuil",
                    project: "FreeDiscover, discover free activities near your home",
                    details: [
                        "Figma, native iOS frontend, SwiftUI, Swift, XCode, Airtable API, Git/GitHub"
                    ]
                },
                {
                    title: "Apple Foundation Program",
                    period: "June 2023",
                    school: "Simplon Lille",
                    project: "MyExotic, ingredient indexing for specialty grocery stores",
                    details: [
                        "Figma, native iOS frontend, Swift, SwiftUI, XCode"
                    ]
                },
                {
                    title: "Bachelor's degree in Foreign Languages (English, German, Dutch)",
                    period: "2013 - 2016",
                    school: "Université du Littoral Côte d'Opale",
                    project: "",
                },

            ],
            projects: [
                {
                    name: "Scrontch (cross-platform), grocery and recipe app",
                    details: [
                        "Java Springboot backend, JWToken, Spring Security, microservice architecture, RabbitMQ",
                        "Cross-platform app (Chrome/browser, iOS, Android) with Flutter, admin dashboard with React",
                        "Deployed on Linux Ubuntu VPS, Nginx"
                    ]
                },
                {
                    name: "MaybeSimilar, vote on whether songs sound similar",
                    details: [
                        "Node.js, React.js",
                    ]
                },

            ],
            skills: {
                technical: ["Java", "Spring", "React", "Node.js", "SQL", "Flutter", "Jetpack Compose", "Kotlin", "JUnit"],
                languages: ["English (Fluent)", "French (Native)"],
                other: ["Git", "Gitflow", "Agile/Scrum", "Docker", "Nginx",]
            }
        },
        fr: {
            header: {
                name: "Victor LEMAITRE",
                title: "Développeur full-stack",
                description: "Anciennement traducteur anglais-français indépendant, je me suis découvert une véritable passion pour le développement lors de ma reconversion professionnelle. Sérieux, autonome et rigoureux, je saurai mettre mes qualités à profit dans une équipe.",
                contact: {
                    email: {
                        display: "victor.lemaitre1@gmail.com",
                        link: "mailto:victor.lemaitre1@gmail.com"
                    },
                    phone: {
                        display: "06 35 90 74 91",
                        link: "tel:+33635907491"
                    },
                    linkedin: {
                        display: "linkedin.com/in/victor-lemaitre/",
                        link: "linkedin.com/in/victor-lemaitre/"
                    },
                    location: "Roubaix, France",
                    github: {
                        display: "github.com/victorlmtr",
                        link: "https://github.com/victorlmtr"
                    }
                }
            },
            experience: [
                {
                    title: "Stage Développeur backend/frontend",
                    period: "Sept 2024 - Déc 2024",
                    company: "Rémi Pozzo",
                    project: "1civil.it, application de signalement réalisée en petite équipe",
                    details: [
                        "Backend Java Springboot, architecture microservices, sécurisation avec Spring Security, JWT",
                        "Frontend mobile (iOS, Android) Flutter, création d’un signalement avec géolocalisation",
                        "Maquette Figma, Docker, PostgreSQL, RabbitMQ, Angular, Trello…"
                    ]
                },
                {
                    title: "Traducteur anglais-français",
                    period: "Fév 2016 - Déc 2024",
                    company: "À mon compte",
                    project: "",
                    details: [
                        "Sous-titrage de conférences (Adobe Max, Adobe Summit, Atlassian Team…)",
                        "Traduction technique (informatique, médicale, jeux vidéo)",
                        "Interprétation, transcription français-anglais, relecture/correction"
                    ]
                },
                {
                    title: "Traducteur interprète",
                    period: "Juin 2019 - Août 2019",
                    company: "CRIT, Lumbres, FR",
                    project: "",
                    details: [
                        "Traduction technique pour une station d’épuration",
                        "Interprétation/dialogue avec les travailleurs étrangers sur le chantier",
                    ]
                },
                {
                    title: "Stage de traduction anglais-français",
                    period: "Jan 2016 - Fév 2016",
                    company: "Language Insight, Londres, UK",
                    project: "",
                    details: [
                        "Traduction technique (médicale, informatique) en entreprise",
                    ]
                },

            ],
            education: [
                {
                    title: "Titre Pro Concepteur développeur d’applications",
                    period: "2023 - 2024",
                    school: "AFPA Roubaix",
                    project: "Scrontch, application de gestion de courses et de recettes",
                    details: [
                        "Backend Java Springboot, JWToken, Spring Security",
                        "Frontend Android natif Jetpack Compose, frontend admin Angular",
                        "Déploiement sur VPS Linux Ubuntu, Nginx"
                    ]
                },
                {
                    title: "Apple Foundation Program Extended",
                    period: "Octobre 2023",
                    school: "Simplon Montreuil",
                    project: "FreeDiscover, découvrir des activités gratuites à proximité de chez soi",
                    details: [
                        "Figma, développement natif iOS, SwiftUI, Swift, XCode, géolocalisation, API Airtable, Git/GitHub"
                    ]
                },
                {
                    title: "Apple Foundation Program",
                    period: "Juin 2023",
                    school: "Simplon Lille",
                    project: "MyExotic, référencement des ingrédients des épiceries spécialisées",
                    details: [
                        "Figma, développement natif iOS, Swift, SwiftUI, XCode"
                    ]
                },
                {
                    title: "Licence de Langues Etrangères Appliquées anglais, allemand, néerlandais",
                    period: "2013 - 2016",
                    school: "Université du Littoral Côte d'Opale",
                    project: "",
                },

            ],
            projects: [
                {
                    name: "Scrontch multiplateformes, application de gestion de courses et de recettes",
                    details: [
                        "Backend Java Springboot, JWToken, Spring Security, architecture microservices, RabbitMQ",
                        "Frontend multiplateformes (Chrome/browser, iOS, Android) Flutter, frontend admin React",
                        "Déploiement sur VPS Linux Ubuntu, Nginx"
                    ]
                },
                {
                    name: "MaybeSimilar, site internet de comparaison d’extraits musicaux",
                    details: [
                        "Node.js, React.js",
                    ]
                },

            ],
            skills: {
                technical: ["Java", "Spring", "React", "Node.js", "SQL", "Flutter", "Jetpack Compose", "Kotlin", "JUnit"],
                languages: ["English (Courant)", "French (Natif)"],
                other: ["Git", "Gitflow", "Agile/Scrum", "Docker", "Nginx"]
            }
        }
    };

    const handleLanguageChange = (lang) => {
        setSelectedLang(lang);
    };

    const renderExperienceItem = (exp, index) => (
        <div key={index} className="experience-item">
            <div className="experience-header">
                <h3>{exp.title}</h3>
                <span className="period">{exp.period}</span>
            </div>
            <p className="company-name">{exp.company}</p>
            <div className="project-details">
                {exp.project && (
                    <p className="project-title">
                        {selectedLang === 'en' ? 'Notable project: ' : 'Projet notable : '}
                        {exp.project}
                    </p>
                )}
                {exp.details && exp.details.map((detail, i) => (
                    <p key={i} className="detail-item">→ {detail}</p>
                ))}
            </div>
        </div>
    );


    const renderEducationItem = (edu, index) => (
        <div key={index} className="education-item">
            <div className="education-header">
                <h3>{edu.title}</h3>
                <span className="period">{edu.period}</span>
            </div>
            <p className="school-name">{edu.school}</p>
            {(edu.project || (edu.details && edu.details.length > 0)) && (
                <div className="project-details">
                    {edu.project && (
                        <p className="project-title">
                            {selectedLang === 'en' ? 'Notable project: ' : 'Projet notable : '}
                            {edu.project}
                        </p>
                    )}
                    {edu.details && edu.details.map((detail, i) => (
                        <p key={i} className="detail-item">→ {detail}</p>
                    ))}
                </div>
            )}
        </div>
    );

    return (
        <div className="page-container">
            <h1>
                <a href="https://victorl.xyz" className="home-link">victorl.xyz</a>/dev/
            </h1>

            <div className="language-selector">
                <span className="selector-text">
                    {selectedLang === 'fr' ? 'langue' : 'language'}
                </span>
                <div
                    className={`language-option ${selectedLang !== 'fr' ? 'unselected' : ''}`}
                    onClick={() => handleLanguageChange('fr')}
                >
                    {selectedLang === 'fr' && <span className="language-arrow">▶</span>}
                    fr
                </div>
                <div
                    className={`language-option ${selectedLang !== 'en' ? 'unselected' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                >
                    {selectedLang === 'en' && <span className="language-arrow">▶</span>}
                    en
                </div>
            </div>
            <Link to="/" className="back-link">
                {selectedLang === 'fr' ? '← Retour' : '← Back'}
            </Link>

            <div className="resume-container">
                <section className="header-section">
                    <div className="header-left">
                        <h2>{resumeContent[selectedLang].header.name}</h2>
                        <h3>{resumeContent[selectedLang].header.title}</h3>
                        <p>{resumeContent[selectedLang].header.description}</p>
                    </div>
                    <div className="header-right">
                        <p>
                            <a href={resumeContent[selectedLang].header.contact.email.link}
                                className="contact-link">
                                {resumeContent[selectedLang].header.contact.email.display}
                            </a>

                        </p>
                        <p>
                            <a href={resumeContent[selectedLang].header.contact.phone.link}
                                className="contact-link">
                                {resumeContent[selectedLang].header.contact.phone.display}
                            </a>
                        </p>
                        <p>
                            <a href={resumeContent[selectedLang].header.contact.linkedin.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="contact-link">
                                {resumeContent[selectedLang].header.contact.linkedin.display}
                            </a>
                        </p>
                        <p>{resumeContent[selectedLang].header.contact.location}</p>
                        <p>
                            <a href={resumeContent[selectedLang].header.contact.github.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="contact-link">
                                {resumeContent[selectedLang].header.contact.github.display}
                            </a>
                        </p>
                    </div>
                </section>

                <section className="resume-section">
                    <h2>{selectedLang === 'en' ? 'Experience' : 'Expérience'}</h2>
                    {resumeContent[selectedLang].experience.map((exp, index) =>
                        renderExperienceItem(exp, index)
                    )}
                </section>

                <section className="resume-section">
                    <h2>{selectedLang === 'en' ? 'Education' : 'Formation'}</h2>
                    {resumeContent[selectedLang].education.map((edu, index) =>
                        renderEducationItem(edu, index)
                    )}
                </section>

                <section className="resume-section">
                    <h2>{selectedLang === 'en' ? 'Personal Projects' : 'Projets Personnels'}</h2>
                    {resumeContent[selectedLang].projects.map((project, index) => (
                        <div key={index} className="project-item">
                            <h3 className="project-name">{project.name}</h3>
                            {project.details.map((detail, i) => (
                                <p key={i} className="detail-item">→ {detail}</p>
                            ))}
                        </div>
                    ))}
                </section>

                <section className="resume-section">
                    <h2>{selectedLang === 'en' ? 'Skills' : 'Compétences'}</h2>
                    <div className="skills-container">
                        <div className="skills-group">
                            <h3>{selectedLang === 'en' ? 'Technical' : 'Techniques'}</h3>
                            <div className="skills-list">
                                {resumeContent[selectedLang].skills.technical.map((skill, index) => (
                                    <span key={index} className="skill-item">{skill}</span>
                                ))}
                            </div>
                        </div>
                        <div className="skills-group">
                            <h3>{selectedLang === 'en' ? 'Languages' : 'Langues'}</h3>
                            <div className="skills-list">
                                {resumeContent[selectedLang].skills.languages.map((lang, index) => (
                                    <span key={index} className="skill-item">{lang}</span>
                                ))}
                            </div>
                        </div>
                        <div className="skills-group">
                            <h3>{selectedLang === 'en' ? 'Other' : 'Autres'}</h3>
                            <div className="skills-list">
                                {resumeContent[selectedLang].skills.other.map((skill, index) => (
                                    <span key={index} className="skill-item">{skill}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Link to="/" className="back-link">
                {selectedLang === 'fr' ? '← Retour' : '← Back'}
            </Link>
        </div>
    );
}

export default Dev;