import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';

function Home() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedSubIndex, setSelectedSubIndex] = useState(-1);
    const navigate = useNavigate();

    const menuItems = [
        {
            label: 'dev',
            path: '/dev',
            subItems: [
                { label: 'scrontch', url: 'https://github.com/victorlmtr/scrontch-frontend' },
                { label: 'maybe-similar', url: 'https://github.com/victorlmtr/maybe-similar' }
            ]
        },
        { label: 'github', url: 'https://github.com/victorlmtr' },
        { label: 'linkedin', url: 'https://linkedin.com/in/victor-lemaitre' }
    ];

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowUp':
                if (selectedSubIndex > -1 && selectedIndex === 0) {
                    setSelectedSubIndex(prev => prev > 0 ? prev - 1 : -1);
                } else {
                    setSelectedSubIndex(-1);
                    setSelectedIndex(prev => prev > 0 ? prev - 1 : menuItems.length - 1);
                }
                break;
            case 'ArrowDown':
                if (selectedIndex === 0 && selectedSubIndex < menuItems[0].subItems.length - 1) {
                    setSelectedSubIndex(prev => prev < menuItems[0].subItems.length - 1 ? prev + 1 : prev);
                } else {
                    setSelectedSubIndex(-1);
                    setSelectedIndex(prev => prev < menuItems.length - 1 ? prev + 1 : 0);
                }
                break;
            case 'Enter':
                if (selectedSubIndex > -1 && selectedIndex === 0) {
                    window.location.href = menuItems[0].subItems[selectedSubIndex].url;
                } else {
                    const item = menuItems[selectedIndex];
                    if (item.url) {
                        window.location.href = item.url;
                    } else {
                        navigate(item.path);
                    }
                }
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedIndex, selectedSubIndex]);

    return (
        <div className="home-container">
            <h1 className="title">victorl.xyz</h1>
            <nav className="menu">
                {menuItems.map((item, index) => (
                    <div key={item.label}>
                        <div
                            className={`menu-item ${selectedIndex === index && selectedSubIndex === -1 ? 'selected' : ''}`}
                            onClick={() => {
                                setSelectedSubIndex(-1);
                                setSelectedIndex(index);
                                if (item.url) {
                                    window.location.href = item.url;
                                } else {
                                    navigate(item.path);
                                }
                            }}
                            onMouseEnter={() => {
                                setSelectedIndex(index);
                                setSelectedSubIndex(-1);
                            }}
                        >
                            <span className="selector">{selectedIndex === index && selectedSubIndex === -1 ? '▶' : ' '}</span>
                            <span className="label">{item.label}</span>
                        </div>
                        {item.subItems && (
                            <div className="submenu">
                                {item.subItems.map((subItem, subIndex) => (
                                    <div
                                        key={subItem.label}
                                        className={`menu-item sub-item ${selectedIndex === index && selectedSubIndex === subIndex ? 'selected' : ''}`}
                                        onClick={() => window.location.href = subItem.url}
                                        onMouseEnter={() => {
                                            setSelectedIndex(index);
                                            setSelectedSubIndex(subIndex);
                                        }}
                                    >
                                        <span className="selector">{selectedIndex === index && selectedSubIndex === subIndex ? '▶' : ' '}</span>
                                        <span className="label">{subItem.label}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </nav>
        </div>
    );
}

export default Home;