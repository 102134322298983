import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Pages.css';

function Food() {
    return (
        <div className="page-container">
            <h1>Food</h1>
            <div className="content">
                {/* to be implemented */}
                <p>Coming soon...</p>
            </div>
            <Link to="/" className="back-link">← Back</Link>
        </div>
    );
}

export default Food;